<div class="collapse mb-2" [ngClass]="{'show':tekst.length==0}">
  <h1>Systemoversikt</h1>
  <div class="row">
    <div class="col-12 col-md-9">
      <p>
        Bergen kommune sin samlede oversikt over programmer, systemer og komponenter. Systemoversikten skal gi
        overordnet informasjon om it-systemer og løsninger som benyttes i Bergen kommune sin tjenesteproduksjon.
        Søket er globalt for alt som er registrert i Systemoversikten
      </p>
      <p>
        <i>Oversikten er rollebasert og informasjon blir gjort tilgjengelig ut fra den funksjon du har som ansatt/leder
          eller rolle opp mot et eller flere systemer.</i>
      </p>
    </div>
  </div>
</div>

<div class="collapse mt-5" [ngClass]="{'show':tekst.length==0}">

  <div *ngIf="dataForPerson" class="row">

    <div *ngIf="dataForPerson.oppdragPerson && dataForPerson.oppdragPerson.length>0 && pageAccess?.oppdrag.listAccess" class="col-12 col-md-6 col-lg-4">
      <div class="font-italic">Mine oppdrag</div>
      <div *ngFor="let item of dataForPerson.oppdragPerson" class="list-group">
        <a [routerLink]="['/oppdrag', item.oppdragId]"
          class="list-group-item list-group-item-action flex-column align-items-start">
          <h5 class="mb-1">{{item.oppdrag.navn}}</h5>
          <div class="text-muted">{{item.oppdrag.beskrivelse}} &nbsp;</div>
        </a>
      </div>
    </div>

    <div *ngIf="dataForPerson.personSystemRolle && dataForPerson.personSystemRolle.length >0"
      class="col-12 col-md-6 col-lg-4">
      <div class="font-italic">Mine systemer</div>
      <div *ngFor="let item of dataForPerson.personSystemRolle" class="list-group">
        <a [routerLink]="['/itsystemer', item.systemId]"
          class="list-group-item list-group-item-action flex-column align-items-start">
          <h5 class="mb-1">{{item.system.navn}}</h5>
          <div class="text-muted">{{item.system.beskrivelse}} &nbsp;</div>
        </a>
      </div>
    </div>

    <div *ngIf="dataForPerson.personLeverandorRolle && dataForPerson.personLeverandorRolle.length >0 && pageAccess?.leverandorer.listAccess"
      class="col-12 col-md-6 col-lg-4">
      <div class="font-italic">Mine Leverandører</div>
      <div *ngFor="let item of dataForPerson.personLeverandorRolle" class="list-group">
        <a [routerLink]="['/leverandorer', item.leverandorId]"
          class="list-group-item list-group-item-action flex-column align-items-start">
          <h5 class="mb-1">{{item.leverandor.navn}}</h5>
          <div class="text-muted">{{item.leverandor.url}} &nbsp;</div>
        </a>
      </div>
    </div>

  </div>

</div>
